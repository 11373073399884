import React, { useMemo } from "react";
import styled, { css } from "styled-components";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { extractValueFromMainTitle, sanitizeHtmlDefault } from "utils/string";
import { motion, Variants } from "framer-motion";
import { colorsUtils, breakpoint } from "@d2c-ui-components-react";
import { Image } from "shared-components/Image";

const cardVariants: Variants = {
  offscreen: {
    y: 200,
    opacity: 0
  },
  onscreen: {
    y: 0,
    opacity: 1,
    transition: {
      type: "moveUpAndFadeIn",
      duration: 0.8
    }
  }
};

const CardBlock = styled(Box) <{ alignment: string }>`
  display: flex;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 0;
  flex-direction: column-reverse;

  ${breakpoint("sm")`
    padding: 1rem 0;
  `}

  ${breakpoint("lg")`
    padding: 2rem 0;
    flex-direction: ${(props) =>
      props?.alignment === "right" ? "row-reverse" : "row"};
  `}
`;

const ContentSection = styled.div`
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 1rem 0;

  ${breakpoint("md")`
    width: 50%;
    padding: 0 1rem;
  `}
`;

const ImageSection = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 0;

  ${breakpoint("md")`
    width: 50%;
    padding: 0;
  `}
`;

const Heading = styled(Typography)`
  &.MuiTypography-root {
    font-weight: 700;
    font-size: 16px!important;
    line-height: 20px;
    color: ${colorsUtils.fwdWhite};
    margin-bottom: 8px;

    ${breakpoint("md")`
      font-size: 22px!important;
      line-height: 28px;
      margin-bottom: 12px;
    `}

    ${breakpoint("xl")`
      font-size: 25px!important;
      line-height: 31.25px;
      margin-bottom: 16px;
    `}
  }
`;

const SubHeading = styled(Typography)`
  &.MuiTypography-root {
    font-weight: 700;
    font-size: 16px!important;
    line-height: 20px;
    color: ${colorsUtils.fwdWhite};
    margin-bottom: 8px;

    ${breakpoint("md")`
      font-size: 22px!important;
      line-height: 28px;
      margin-bottom: 12px;
    `}

    ${breakpoint("xl")`
      font-size: 25px!important;
      line-height: 31.25px;
      margin-bottom: 16px;
    `}
  }
`;

const Title = styled(Typography)`
  &.MuiTypography-root {
    font-weight: 900;
    font-size: 31px!important;
    line-height: 38.75px;
    color: ${colorsUtils.fwdWhite};
    margin-bottom: 16px;

    ${breakpoint("md")`
      font-size: 42px!important;
      line-height: 48px;
      margin-bottom: 20px;
    `}

    ${breakpoint("xl")`
      font-size: 56px!important;
      line-height: 61.6px;
      margin-bottom: 24px;
    `}
  }
`;

const Description = styled.div`
  color: ${colorsUtils.fwdWhite};
  font-size: 16px;
  line-height: 24px;
  font-weight: 450;

  ${breakpoint("md")`
    font-size: 18px;
    line-height: 26px;
  `}

  ${breakpoint("xl")`
    font-size: 20px;
    line-height: 30px;
  `}
`;

export interface iHorizontalCardProps {
  index: number;
  image?: string;
  alignment: "left" | "right";
  heading?: string;
  title?: string;
  description?: string;
}

const HorizontalCard = ({
  index,
  image,
  alignment,
  heading,
  title,
  description
}: iHorizontalCardProps) => {
  const { isMainTitle, mainTitle } = extractValueFromMainTitle(heading || "");
  return (
    <motion.div
      initial="offscreen"
      whileInView="onscreen"
      viewport={{ once: true, amount: 0.8 }}
    >
      <CardBlock alignment={alignment}>
        <ContentSection>
          <motion.div variants={cardVariants}>
            { index === 0 && <Heading variant={isMainTitle? 'h1' : 'h2'}>{mainTitle}</Heading>}
            { index > 0 && <SubHeading>{mainTitle}</SubHeading>}
            <Title variant="h3">{title}</Title>
            <Description
              dangerouslySetInnerHTML={{
                __html: sanitizeHtmlDefault(description || "")
              }}
            />
          </motion.div>
        </ContentSection>
        <ImageSection>
          <motion.div variants={cardVariants} style={{ width: "45%" }}>
            <Image url={image} width="100%" height="auto" />
          </motion.div>
        </ImageSection>
      </CardBlock>
    </motion.div>
  );
};

export default HorizontalCard;
