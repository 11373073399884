import { breakpoint } from "@d2c-ui-components-react";
import { BrandIcon } from "components/BrandIcon";
import { fwdColors } from "config/fwd-colors";
import styled from "styled-components";

export const ModernCardListReminderWrapper = styled.div`
  display: flex;
  padding: 16px;
  border: 1px solid #fee8a0;
  border-radius: 8px;
  background-color: #fff6d9;
  line-height: 20px;
  margin: 40px 0;
  flex-direction: column;
  position: relative;

  ${breakpoint("lg")`
    padding-right: 40px;
    flex-direction: row;
  `}
`;
export const ModernCardListWrapper = styled.div`
  max-width: 1288px;
  padding: 0 32px;

  ${ModernCardListReminderWrapper}:first-child {
    margin-top: 0;
  }
`;

export const ModernCardListReminderIcon = styled(BrandIcon)`
  margin-right: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  svg path {
    color: ${fwdColors.orange};
  }
`;
export const ModernCardListReminderHeading = styled.div`
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  margin-right: 16px;
  margin-bottom: 8px;
  white-space: nowrap;

  ${breakpoint("lg")`
      margin-bottom: 0;
    `}

  > div {
    display: flex;

    ${breakpoint("lg")`
      justify-content: center;
    `}

    > div {
      display: flex;
    }
  }
`;
export const ModernCardListReminderDescription = styled.div`
  font-size: 14px;
  line-height: 18px;
  display: flex;
  font-weight: 450;
  margin-top: -2px;

  p {
    margin-bottom: 0;
  }

  ${breakpoint("lg")`
    font-size: 16px;
    line-height: 24px;
    padding-left: 16px;
    border-left: 1px solid #dbdfe1;
  `}

  a {
    color: ${fwdColors.orange} !important;
    font-weight: 700;
  }
`;

export const ModernCardListReminderDismissButton = styled.a`
  cursor: pointer;
  color: #000 !important;
  position: absolute;
  top: 16px;
  right: 16px;
  width: 24px;
  height: 24px;

  svg {
    width: 100%;
    height: 100%;
  }
`;
