import { BrandIcon } from "components/BrandIcon";
import { Reminder } from "components/Reminder";
import { ReminderPosition } from "components/Reminder/reminder.type";
import DOMPurify from "isomorphic-dompurify";
import { FC, useMemo } from "react";
import { useI18nContext } from "utils/hooks";
import { resolveAbsoluteUrl } from "utils/route";
import {
  ModernCardListCard,
  ModernCardListCardDescription,
  ModernCardListCardIcon,
  ModernCardListCardInfo,
  ModernCardListCards,
  ModernCardListCardTitle,
  ModernCardListCta,
  ModernCardListCtaBlock,
  ModernCardListCtaDescription,
  ModernCardListDescription,
  ModernCardListReminder,
  ModernCardListTitle,
  ModernCardListTitleBlock,
  ModernCardListWrapper,
} from "./card-list.style";
import { CSCardListProps, ModernCardListCtaPosition } from "./card-list.type";

type CtaBlockProps = CSCardListProps["data"]["cta"];
const CtaBlock: FC<CtaBlockProps> = (props) => {
  const i18nContext = useI18nContext();
  const {
    cta_title,
    cta_description,
    cta_icon,
    cta_url,
    ga_label,
    open_in_new_tab,
    cta_position,
    cta_design_settings,
  } = props;
  return (
    <ModernCardListCtaBlock position={cta_position}>
      {cta_description && (
        <ModernCardListCtaDescription
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(cta_description),
          }}
        />
      )}
      <ModernCardListCta
        href={resolveAbsoluteUrl(cta_url || "/", i18nContext)}
        openInNewTab={open_in_new_tab}
        gaEventLabel={cta_title}
        gaEvent={ga_label}
        gtmComponentName="USPBlock"
        design={cta_design_settings}
        position={cta_position}
      >
        <BrandIcon icon={cta_icon || "arrow-right"} />
        <span>{cta_title}</span>
      </ModernCardListCta>
    </ModernCardListCtaBlock>
  );
};

export const ModernCardList: FC<CSCardListProps> = (props) => {
  const i18nContext = useI18nContext();
  let { data } = props;
  const groupedCards = useMemo(() => {
    const result = [[]] as any[][];
    let length = 0;
    props.data.usp_section.forEach((card, index) => {
      result[length].push(card);

      if (
        (length % 2 === 0 && 2 + length * 7 === index) ||
        (length % 2 !== 0 && 6 + length * 7 === index)
      ) {
        result.push([]);
        length += 1;
      }
    });

    return result;
  }, [props.data.usp_section]);

  return (
    <ModernCardListWrapper
      style={{
        maxWidth: "1288px",
        margin: "0 auto",
      }}
    >
      {data.reminder &&
        data.reminder.length > 0 &&
        data.reminder[0].position === ReminderPosition.ABOVE_TITLE_BLOCK && (
          <ModernCardListReminder {...data.reminder[0]} />
        )}
      <ModernCardListTitleBlock>
        <div>
          <ModernCardListTitle>{data.title}</ModernCardListTitle>
          <ModernCardListDescription
            dangerouslySetInnerHTML={{ __html: data.description }}
          />
        </div>
        {data.cta &&
          data.cta.cta_position ===
            ModernCardListCtaPosition.RIGHT_OF_TITLE_BLOCK && (
            <CtaBlock {...data.cta} />
          )}
      </ModernCardListTitleBlock>
      {data.reminder &&
        data.reminder.length > 0 &&
        data.reminder[0].position === ReminderPosition.BELOW_TITLE_BLOCK && (
          <ModernCardListReminder {...data.reminder[0]} />
        )}
      <ModernCardListCards>
        {groupedCards.map((cards) => (
          <div>
            {cards.map((card) => (
              <ModernCardListCard
                href={resolveAbsoluteUrl(card.usp_url, i18nContext)}
              >
                <ModernCardListCardIcon icon={card.usp_icon} />
                <ModernCardListCardInfo>
                  <ModernCardListCardTitle>
                    {card.usp_title}
                  </ModernCardListCardTitle>
                  <ModernCardListCardDescription
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(card.usp_description),
                    }}
                  />
                </ModernCardListCardInfo>
              </ModernCardListCard>
            ))}
          </div>
        ))}
      </ModernCardListCards>
      {data.cta &&
        data.cta.cta_position === ModernCardListCtaPosition.BOTTOM_OF_BLOCK && (
          <CtaBlock {...data.cta} />
        )}
    </ModernCardListWrapper>
  );
};
