import CloseIcon from "@material-ui/icons/Close";
import React, { FC, useEffect, useRef, useState } from "react";
import { useI18nContext } from "utils/hooks";
import { resolveAbsoluteUrlInParagraph } from "utils/route";
import {
    ModernCardListReminderDescription,
    ModernCardListReminderDismissButton,
    ModernCardListReminderHeading,
    ModernCardListReminderIcon,
    ModernCardListReminderWrapper
} from "./reminder.style";
import { CSReminder } from "./reminder.type";

type ModernCardListReminderProps = CSReminder & {
  className?: string;
};

export const Reminder: FC<ModernCardListReminderProps> = (props) => {
  const [shouldDisplay, setShouldDisplay] = useState(false);
  const i18nContext = useI18nContext();
  const ref = useRef<any>();

  useEffect(() => {
    let reminderVersion = parseInt(
      localStorage.getItem("cardListReminderVersion") || "-1",
      10
    );
    let reminderChecked =
      localStorage.getItem("cardListReminderChecked") || "0";
    if (props._version !== reminderVersion || reminderChecked === "0") {
      setShouldDisplay(true);
      localStorage.setItem(
        "cardListReminderVersion",
        props._version.toString()
      );
    }
  }, [props._version]);

  useEffect(() => {
    if (!ref.current) {
      return;
    }
    const el = ref.current as HTMLDivElement;
    const linkClickHandler = (ev: any) => {
      let target = ev.target as HTMLAnchorElement;
      let parent = target as any;
      let deep = 0;
      while (parent?.tagName?.toLowerCase() !== "a" && deep < 3) {
        parent = parent?.parentElement;
        deep += 1;
      }
      target = parent;

      if (target && target.getAttribute("href")) {
        ev.preventDefault();
        ev.stopPropagation();
        const href = target.getAttribute("href") || "";
        if (href.includes("#same-section:")) {
          const rest = href.split("#same-section:")[1];
          let tabId = "";
          let sectionId = "";
          if (rest.includes(":tab:")) {
            // #same-section:tab-click-component-<index>:tab:<tab-uid>
            const ids = rest.split(":tab:");
            sectionId = ids[0];
            tabId = ids[1];
          } else {
            // #same-section:tab-click-component-<index>
            sectionId = rest;
          }

          if (sectionId) {
            const section = document.getElementById(sectionId);

            if (section) {
              section.scrollIntoView({
                behavior: "smooth",
              });
            }
          }

          if (tabId) {
            const tabNavItem = document.getElementById(`tab-nav-${tabId}`);

            if (tabNavItem) {
              const clickEvent = new MouseEvent("click", {
                bubbles: true,
              });
              tabNavItem.dispatchEvent(clickEvent);
            }
          }
        }
      } else {
        return;
      }
    };

    el.addEventListener("click", linkClickHandler);

    return () => el.removeEventListener("click", linkClickHandler);
  });

  const dismiss = (ev: React.SyntheticEvent) => {
    ev.preventDefault();
    localStorage.setItem("cardListReminderChecked", "1");
    setShouldDisplay(false);
  };

  if (!shouldDisplay) {
    return <></>;
  }

  return (
    <ModernCardListReminderWrapper className={props.className} ref={ref}>
      <ModernCardListReminderHeading>
        <div>
          <ModernCardListReminderIcon
            icon={props.icon}
            width="20px"
            height="20px"
          ></ModernCardListReminderIcon>
          <div>{props.display_title || props.title}</div>
        </div>
      </ModernCardListReminderHeading>
      <ModernCardListReminderDescription>
        <div
          dangerouslySetInnerHTML={{
            __html: resolveAbsoluteUrlInParagraph(
              props.description,
              i18nContext
            ),
          }}
        ></div>
      </ModernCardListReminderDescription>
      <ModernCardListReminderDismissButton onClick={dismiss}>
        <CloseIcon />
      </ModernCardListReminderDismissButton>
    </ModernCardListReminderWrapper>
  );
};

export const CSReminderBlock: FC<{
  data: CSReminder;
}> = (props) => {
  return <Reminder {...props.data} />;
};
