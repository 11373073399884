export enum ReminderPosition {
  ABOVE_TITLE_BLOCK = "above_title_block",
  BELOW_TITLE_BLOCK = "below_title_block",
}

export interface CSReminder {
  icon: string;
  title: string;
  display_title: string;
  description: string;
  position: ReminderPosition;
  uid: string;
  _version: number;
}
