import styled, { css } from "styled-components";
import { generateUniqSerial } from "utils/uid";
import { breakpoint, useResponsive } from "@d2c-ui-components-react";
import { colorsUtils } from "@d2c-ui-components-react";
import { fwdColors } from "config/fwd-colors";
import { BrandIcon } from "components/BrandIcon";
import { Link } from "shared-components/Link";
import {
  ModernCardListCtaDesign,
  ModernCardListCtaPosition,
} from "./card-list.type";
import { Reminder } from "components/Reminder";

export const CardListTableWrapper = styled.div<{
  color?: string;
}>`
  z-index: 0;
  margin-left: 0;
  font-size: 1rem;
  line-height: 1.5rem;
  white-space: pre-wrap;
  width: calc(100% + 24px);
  overflow: auto;
  border-radius: 8px 0 0 0;
  border-left: 1px solid ${fwdColors.orange};

  > p {
    color: ${(props) => props.color};
  }

  ${breakpoint("md")`
    font-size: 1.25rem;
    font-weight: 300;
    line-height: 1.875rem;
  `}

  ${breakpoint("sm")`
    width: auto;
  `}

  [class*="Table__PaddingWrap"] {
    padding: 0;
  }
  [class*="Table__TableWrapper"] {
    border-left: none !important;
  }

  [class*="Table__InnerWrapper"] {
    padding: 0;

    > [class*="MainLayout__ContentWrapper"] {
      padding: 0 !important;
    }

    .navigation-button {
      display: none;
    }
  }

  [class*="Table__Footnote"] {
    padding: 0;
  }

  > [class*="MainLayout__ContentWrapper"] {
    display: block;
    padding: 0 !important;
    width: max-content;

    ${breakpoint("sm")`
      width: auto;
    `}

    [class*="MainLayout__ContentContainer"] {
      display: block;
    }
  }

  [class*="MainLayout__ContentWrapper"] {
    display: block;

    [class*="MainLayout__ContentContainer"] {
      display: block;
    }
  }

  [class*="Table__TableInfoWrap"] {
    padding: 0;
  }

  table {
    td {
      vertical-align: top;
      font-size: 1rem;
    }
    figure {
      width: unset !important;
    }

    img {
      width: unset !important;
    }
  }

  p {
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.5rem;

    ${breakpoint("md")`
      font-weight: 300;
      line-height: 1.875rem;
    `}
  }

  table {
    vertical-align: baseline;

    thead,
    tbody,
    tfoot {
      vertical-align: baseline;
    }

    td,
    th {
      font-size: 1rem;
      line-height: 1.5rem;

      ${breakpoint("md")`
        font-weight: 300;
        line-height: 1.875rem;
      `}
    }

    strong {
      font-weight: bold !important;
    }
  }
`;

export const ModernCardListReminderWrapper = styled.div`
  display: flex;
  padding: 16px;
  border: 1px solid #fee8a0;
  border-radius: 8px;
  background-color: #fff6d9;
  line-height: 20px;
  margin: 40px 0;
  flex-direction: column;
  position: relative;

  ${breakpoint("lg")`
    flex-direction: row;
  `}
`;

export const ModernCardListReminder = styled(Reminder)`
  margin-top: 0;
`;

export const ModernCardListWrapper = styled.div`
  max-width: 1288px;
  padding: 0 16px;

  ${ModernCardListReminderWrapper}:first-child {
    margin-top: 0;
  }

  ${breakpoint("lg")`
  padding: 0 32px;
  `}
`;
export const ModernCardListTitle = styled.div`
  font-size: 31px;
  font-weight: 700;
  line-height: 39px;
  margin-bottom: 16px;

  ${breakpoint("lg")`
  font-size: 39px;
  font-weight: 700;
  line-height: 48.75px;
    `}
`;
export const ModernCardListDescription = styled.div`
  font-size: 16px;
  font-weight: 450;
  line-height: 24px;

  ${breakpoint("md")`
  font-size: 20px;
  font-weight: 450;
  line-height: 30px;
    `}
`;
export const ModernCardListCard = styled.a`
  display: flex;
  padding: 16px;
  gap: 8px;
  border-radius: 8px;
  box-shadow: 0 2px 8px 0 rgba(24, 48, 40, 0.2);
  background-color: #ffffff;
  align-items: center;

  ${breakpoint("lg")`
  align-items: unset;
  padding: 40px 24px;
    `}
`;
export const ModernCardListCardIcon = styled(BrandIcon)`
  margin-right: 16px;
  img {
    width: 100%;
    height: 100%;
  }
`;
export const ModernCardListCardInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${breakpoint("lg")`
  display: block;
  `}
`;
export const ModernCardListCardTitle = styled.div`
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  margin-bottom: 8px;

  ${breakpoint("lg")`
  font-size: 25px;
  font-weight: 700;
  line-height: 31px;
  `}
`;
export const ModernCardListCardDescription = styled.div`
  font-size: 14px;
  font-weight: 450;
  line-height: 21px;
  color: #636566;

  p {
    margin-bottom: 0;
  }
`;
export const ModernCardListCardsEvenRow = styled.div``;
export const ModernCardListCardsOddRow = styled.div``;
export const ModernCardListCards = styled.div`
  margin-top: 24px;
  & > div {
    display: grid;
    gap: 12px;
    margin-bottom: 12px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  ${ModernCardListCardIcon} {
    width: 56px !important;
    height: 56px !important;
    flex-basis: 56px;
    flex-shrink: 0;
  }

  ${breakpoint("lg")`
  & > div {
    gap: 24px;
    margin-bottom: 24px;
  }
  & > div:nth-child(odd) {
    grid-template-columns: repeat(3, 1fr);

    ${ModernCardListCard} {
      flex-direction: row;
    }

    ${ModernCardListCardInfo} {
      margin-top: 16px;
    }

    ${ModernCardListCardIcon} {
      width: 88px !important;
      height: 88px !important;
      flex-basis: 88px;
      flex-shrink: 0;
    }
  }

  & > div:nth-child(even) {
    grid-template-columns: repeat(4, 1fr);

    ${ModernCardListCard} {
      flex-direction: column;
      padding: 24px 24px 16px 24px;
      align-items: center;
    }

    ${ModernCardListCardInfo} {
      text-align: center;
    }

    ${ModernCardListCardIcon} {
      width: 64px !important;
      height: 64px !important;
      flex-basis: 64px;
      flex-shrink: 0;
    }
  }
  `}
`;

export const ModernCardListTitleBlock = styled.div`
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;

  ${breakpoint("lg")`
  flex-direction: row;
  justify-content: space-between;
  `}
`;

export const ModernCardListCta = styled(Link)<{
  design: ModernCardListCtaDesign;
  position: ModernCardListCtaPosition;
}>`
  display: inline-flex;
  padding: 10px 16px;
  align-items: center;
  gap: 4px;
  font-family: FWD Circular TT;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 125%;

  ${(props) =>
    props.position === ModernCardListCtaPosition.BOTTOM_OF_BLOCK &&
    css`
      margin-left: auto;
    `}

  ${(props) =>
    props.design === ModernCardListCtaDesign.TRANSPARENT_BACKGROUND &&
    css`
      color: ${fwdColors.orange};
      border-radius: 4px;
      border: 2px solid ${fwdColors.orange};
    `}

  ${(props) =>
    props.design === ModernCardListCtaDesign.WHITE_BACKGROUND &&
    css`
      background: #fff;
      color: #000;
      border-radius: 4px;
      padding: 24px 16px;
      box-shadow: 0 2px 8px 0 rgba(24, 48, 40, 0.2);

      svg {
        width: 24px !important;
        height: 24px !important;
      }

      svg path {
        color: ${fwdColors.orange} !important;
      }

      &:hover {
        background-color: ${fwdColors.orange} !important;
        color: #fff !important;

        svg path {
          color: #fff !important;
        }
      }
    `}

  transition: all 400ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  width: 100%;
  justify-content: center;

  ${breakpoint("lg")`
    display: inline-flex;
    width: fit-content;
    justify-content: unset;
    margin-top: 0;
    margin-left: auto;
    `}

  span {
    margin-top: 3px;
  }

  &:hover {
    background-color: ${fwdColors.orangeLight};
  }

  svg {
    width: 24px !important;
    height: 24px !important;
  }

  svg path {
    color: inherit;
    transition: all 400ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }
`;

export const ModernCardListCtaDescription = styled.div`
  margin-bottom: 16px;
  margin-top: 24px;
  font-size: 16px;
  font-weight: 450;
  line-height: 24px;
  align-self: flex-start;

  p:last-child {
    margin-bottom: 0;
  }

  ${breakpoint("lg")`
    font-size: 20px;
    font-weight: 450;
    line-height: 30px;
    margin-bottom: 0;
    margin-top: 0;
  `}
`;

export const ModernCardListCtaBlock = styled.div<{
  position: ModernCardListCtaPosition;
}>`
  display: flex;
  flex-direction: column;

  ${(props) =>
    props.position === ModernCardListCtaPosition.BOTTOM_OF_BLOCK &&
    css`
      align-items: center;

      ${breakpoint("lg")`
        flex-direction: row;
      margin-top: 40px;
      `}
    `}

  ${(props) =>
    props.position === ModernCardListCtaPosition.RIGHT_OF_TITLE_BLOCK &&
    css`
      ${ModernCardListCtaDescription} {
        margin-bottom: 8px;
      }

      ${breakpoint("lg")`
        max-width: 300px;
      `}
    `}
`;
export const ModernCardListReminderIcon = styled(BrandIcon)`
  margin-right: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  svg path {
    color: ${fwdColors.orange};
  }
`;
export const ModernCardListReminderHeading = styled.div`
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  margin-right: 16px;
  margin-bottom: 8px;

  ${breakpoint("lg")`
      margin-bottom: 0;
    `}

  > div {
    display: flex;
    padding-right: 16px;

    ${breakpoint("lg")`
      justify-content: center;
      border-right: 1px solid #dbdfe1;
    `}

    > div {
      display: flex;
    }
  }
`;
export const ModernCardListReminderDescription = styled.div`
  font-size: 14px;
  line-height: 18px;
  display: flex;

  ${breakpoint("lg")`
   line-height: 24px;
  `}

  a {
    color: ${fwdColors.orange} !important;
    font-weight: 700;
  }
`;

export const ModernCardListReminderDismissButton = styled.a`
  cursor: pointer;
  color: #000 !important;
  position: absolute;
  top: 16px;
  right: 16px;
`;
