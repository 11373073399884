import React, { useMemo } from "react";
import styled from "styled-components";
import get from "lodash/get";
import { colorsUtils, breakpoint } from "@d2c-ui-components-react";
import { isEven } from "utils/numeric";
import HorizontalCard from "./HorizontalCard";
import { extractValueFromMainTitle } from "utils/string";

const ExtendedContainer = styled.div<{
  backgroundColor?: string;
}>`
  background: ${(props) =>
    props.backgroundColor ? props.backgroundColor : colorsUtils.fwdOrange};
`;

const Container = styled.div`
  max-width: 1288px;
  padding: 1rem;
  margin: auto;

  ${breakpoint("md")`
    padding: 4rem 1rem;
  `}
`;
export interface HorizontalCardListProps {
  backgroundColor?: string;
  data: {
    fields: {
      description: {
        value: string;
      };
      title: {
        value: string;
      };
      linkItems: any;
    };
  };
}

const HorizontalCardList = ({
  backgroundColor,
  data
}: HorizontalCardListProps) => {
  const { fields } = data;
  const linkItems = useMemo(() => get(fields, "linkItems", []), [fields]);
  const originTitle = useMemo(() => get(fields, "originTitle.value", ""), [fields]);
  const { mainTitle } = extractValueFromMainTitle(originTitle || "");

  return (
    <ExtendedContainer backgroundColor={backgroundColor}>
      <Container>
        {linkItems?.map((linkItem, index) => {
          return (
            <HorizontalCard
              key={index}
              index={index}
              image={get(linkItem, "fields.image.value.src", "")}
              alignment={isEven(index) ? "left" : "right"}
              heading={index === 0 ? originTitle : mainTitle}
              title={get(linkItem, "displayName", "")}
              description={get(linkItem, "fields.description.value", "")}
            />
          )
        })}
      </Container>
    </ExtendedContainer>
  );
};

export default HorizontalCardList;
