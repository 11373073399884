import {
  CardList as NextGenCardList,
  CARD_LIST_VARIANT as NextGenCardListVariant,
} from "@fwd-dep/nextgen-ui-lib";
import { makeStyles } from "@material-ui/core";
import I18nContext from "i18n/context/LanguageContext";
import { useContext, useState, useMemo, useCallback } from "react";
import styled, { css } from "styled-components";
import { generateUniqSerial } from "utils/uid";
import { breakpoint, useResponsive } from "@d2c-ui-components-react";
import { resolveAbsoluteUrlInParagraph, resolveAbsoluteUrl } from "utils/route";
import { replaceContentStackURL } from "@utils";
import DOMPurify from "isomorphic-dompurify";
import chunk from "lodash/chunk";
import HorizontalCardList from "shared-components/HorizontalCardList";
import clsx from "clsx";
import { fwdColors } from "@fwd-dep/nextgen-ui-lib";
import { extractValueFromMainTitle } from "utils/string";
import { CSTableContentProps, TableContent } from "components/Table/type";
import { CSTableContent } from "components/Table";
import { CardListTableWrapper } from "./card-list.style";
import { CSCardListProps } from "./card-list.type";
import { ModernCardList } from "./ModernCardList";

const CARD_LIST_VARIANT = {
  ...NextGenCardListVariant,
  HK_SUPPORT_PAGE_2: "hk_support_page2",
};

const useStyles = makeStyles((theme) => ({
  CardList: {
    "& .MuiTypography-h5": {
      fontSize: "20px",
      marginTop: "8px",
      marginBottom: "4px",
      lineHeight: "28px",
      [theme.breakpoints.down(600)]: {
        fontSize: "16px",
      },
    },
    "& [class^='makeStyles-imageWrapSmallVariant-']": {
      height: "72px",
    },
    "& picture": {
      height: "100%",
    },
  },
  HorizontalTabItem: {
    "& .MuiContainer-root": {
      padding: "32px 0",
    },

    "& .MuiTypography-h5": {
      [theme.breakpoints.down(720)]: {
        marginTop: "24px",
      },
    },

    "& h2": {
      fontWeight: "700",
      fontSize: "25px",
      lineHeight: "38.75px",
      marginBottom: "24px !important",
      [theme.breakpoints.up(720)]: {
        fontSize: "31px",
        lineHeight: "31.25px",
      },
    },
    "& .richtext-link-common a": {
      color: `${fwdColors.orange}`,
    },
  },
  ModernCardList: {
    "& .MuiContainer-overflowHidden": {
      overflow: "unset",
    },
    "& .MuiTypography-h5": {
      marginTop: "0 !important",
    },
    "& .paddingTopMedium": {
      paddingTop: "0 !important",
    },
    "& [data-locator*='CardList-Description']": {
      fontWeight: "500 !important",
      fontSize: "20px !important",
      marginBottom: "16px !important",
      lineHeight: "30px !important",
    },
    "& [data-locator*='CardList-Container'] > div": {
      maxWidth: "100% !important",
    },
    "& [data-locator*='Grid-Wrapper']": {
      width: "calc(100% + 32px) !important",
      margin: "-16px !important",
    },
    "& [data-locator*='Grid-Item']": {
      paddingLeft: "16px !important",
      paddingRight: "0 !important",
      paddingTop: "16px !important",
      paddingBottom: "0 !important",
    },
    "& .paddingBottomMedium": {
      paddingBottom: "16px",
    },
  },
}));

const Wrapper = styled.div<{
  itemsPerLine: number;
  backgroundColor: string;
  foregroundColor: string;
  useParentPadding?: boolean;
  designSettings: typeof CARD_LIST_VARIANT[keyof typeof CARD_LIST_VARIANT];
  haveMarginTop: boolean;
}>`
  ${(props) =>
    props.designSettings === CARD_LIST_VARIANT.HK_SUPPORT_PAGE &&
    css`
      padding: 24px;
      margin-top: 5px;
      border-radius: 8px;
      box-shadow: 0px 0px 8px 0px rgba(24, 48, 40, 0.2);

      && [data-locator*="Grid-Wrapper"] {
        margin: 0 !important;
      }
      && [data-locator*="Grid-Item"] {
        padding: 0 !important;
        margin-bottom: 16px;
      }
      && .paddingBottomMedium {
        padding-bottom: 0 !important;
      }
    `}

  ${props => props.designSettings === CARD_LIST_VARIANT.HK_SUPPORT_PAGE_2 && css`
    padding: 64px 0;

    ${breakpoint('lg')`
      padding: 80px 0;
    `}
  `}
  h3 {
    font-size: 20px !important;
  }

  .MuiContainer-overflowHidden {
    background: ${(props) =>
      props.backgroundColor ? props.backgroundColor : "#FEF9F4"};
    .MuiContainer-root {
      max-width: 1288px;
    }
  }
  .text-color-orangeLighter {
    color: ${(props) =>
      props.foregroundColor ? props.foregroundColor : "#183028"};
  }
  .MuiGrid-container {
    .MuiGrid-item {
      ${(props) => breakpoint("md")`
        max-width: ${`${100 / props.itemsPerLine}%`};
        flex-basis: ${`${100 / props.itemsPerLine}%`};
      `};
    }
  }

  &.layout-center {
    .MuiGrid-container {
      .MuiGrid-item {
        ${(props) => breakpoint("md")`
        max-width: ${`${100 / (props.itemsPerLine / 2)}%`};
        flex-basis: ${`${100 / (props.itemsPerLine / 2)}%`};
      `};

        ${(props) => breakpoint("lg")`
        max-width: ${`${100 / props.itemsPerLine}%`};
        flex-basis: ${`${100 / props.itemsPerLine}%`};
      `};
      }
    }
  }

  img.allBorderRadiusSmall {
    width: ${(useParentPadding) => (useParentPadding ? "64px" : "51px")};
    height: auto;
    object-fit: initial;
    object-position: initial;

    ${breakpoint("md")`
      width: 62px;
    `}
  }
`;

type Props = {
  cardListProps: any;
};

export const CardList = ({ cardListProps }: Props) => {
  const classes = useStyles();

  return (
    <div
      className={clsx(classes.CardList, {
        [classes.HorizontalTabItem]: cardListProps?.useParentPadding,
        [classes.ModernCardList]:
          cardListProps?.params?.designSetting ===
            CARD_LIST_VARIANT.SHADOWED_CARD ||
          cardListProps?.params?.designSetting ===
            CARD_LIST_VARIANT.HK_SUPPORT_PAGE,
      })}
    >
      <NextGenCardList {...cardListProps} />
    </div>
  );
};

export const CSCardList = (csData: CSCardListProps) => {
  const [page, setPage] = useState<number>(1);
  const isDesktop = useResponsive({ breakpoint: "md", noSsr: true });

  let {
    title,
    description,
    spacing,
    usp_section = [],
    cta,
    items_per_line,
    background_color,
    foreground_color,
    see_more_text,
    design_settings,
    additionalData: { custom_ctas = [] } = {},
    useParentPadding,
    isHorizontalVertical,
    subtitle = {
      text: "",
      icon: "",
    },
  } = csData.data || {};
  const itemsPerLine = items_per_line || 3;
  const { isMainTitle, mainTitle } = extractValueFromMainTitle(title);

  const chunked = useMemo(() => {
    if (design_settings === CARD_LIST_VARIANT.HK_SUPPORT_PAGE) {
      return usp_section;
    }
    let size = itemsPerLine || 3;
    if (isDesktop) size = itemsPerLine * 2;
    return chunk(usp_section, size * page)?.[0] || [];
  }, [isDesktop, usp_section, page, itemsPerLine]);

  const onShowMore = useCallback(() => {
    setPage((prev) => prev + 1);
  }, []);

  const i18nContext = useContext(I18nContext);

  const locale =
    i18nContext.language.languageCode ||
    i18nContext.defaultLanguage.languageCode;

  const data = {
    sitecoreContext: {
      language: locale,
      languageCode: locale,
      availableLanguages: Object.keys(i18nContext.supportedLanguages),
    },
    params: {
      cardVariant: "Medium",
      cardStyle: "Stage",
      cardType: "InfoCard",
      backgroundColor: "orangeLighter",
      paddingTop: spacing?.padding_top || "large",
      paddingBottom: spacing?.padding_bottom || "large",
      designSetting: design_settings || CARD_LIST_VARIANT.LEFT_ICON,
    },
    dictionary: {
      seeMore: see_more_text || "See more",
    },
    onShowMore,
    fields: {
      description: {
        value: resolveAbsoluteUrlInParagraph(description, i18nContext),
      },
      originTitle: {
        value: isHorizontalVertical ? "" : title,
      },
      title: {
        value: isHorizontalVertical ? "" : mainTitle,
      },
      isMainTitle: {
        value: !!isMainTitle,
      },
      subTitle: {
        value: subtitle.text,
      },
      subTitleIcon: {
        value: subtitle.icon,
      },
      itemsPerLine,
      totalLinks: usp_section.length || 0,
      linkItems: chunked.map(
        ({ usp_title, usp_description, usp_icon, table_reference }, i) => {
          const isTable = table_reference && table_reference.length > 0;
          const newUspIcon = replaceContentStackURL(usp_icon);
          const customButton = custom_ctas?.find((e) => e?.index === i + 1);
          return {
            id: generateUniqSerial(),
            name: usp_title,
            displayName: usp_title,
            templateId: "",
            templateName: "",
            renderTable: isTable
              ? () => {
                  return (
                    <CardListTableWrapper>
                      <CSTableContent
                        data={{
                          title: "",
                          description: "",
                          footnote: "",
                          table: table_reference,
                          headerVisible: false,
                        }}
                      />
                    </CardListTableWrapper>
                  );
                }
              : null,
            fields: {
              image: {
                value: {
                  src: newUspIcon,
                  Large: {
                    x1: newUspIcon,
                    x2: newUspIcon,
                  },
                  Small: {
                    x2: newUspIcon,
                    x1: newUspIcon,
                  },
                },
              },
              mobileImage: {
                value: {
                  src: newUspIcon,
                  Large: {
                    x1: newUspIcon,
                    x2: newUspIcon,
                  },
                  Small: {
                    x1: newUspIcon,
                    x2: newUspIcon,
                  },
                },
              },
              title: {
                value: usp_title,
              },
              description: {
                value: resolveAbsoluteUrlInParagraph(
                  usp_description,
                  i18nContext
                ),
              },
              subtitle: {
                value: "",
              },
              linkGALabelName: {
                value: "",
              },
              gaLabelName: {
                value: "",
              },
              gaCategoryName: {
                value: "",
              },
              buttonIcon: {
                fields: {
                  value: {
                    value: replaceContentStackURL(customButton?.icon || ""),
                  },
                },
              },
              buttonMode: {
                fields: {
                  value: {
                    value: "SimpleLink",
                  },
                },
              },
              link: {
                value: {
                  href:
                    replaceContentStackURL(customButton?.url) ||
                    resolveAbsoluteUrl(customButton?.url, i18nContext) ||
                    "",
                  text: customButton?.title,
                  linktype: customButton?.url ? "external" : "modelpopup",
                  target: customButton?.open_in_new_tab ? "_blank" : "_self",
                  modelpopupcontent: customButton?.url
                    ? null
                    : {
                        title: customButton?.modal?.title || "",
                        description: (
                          <div
                            dangerouslySetInnerHTML={{
                              __html: DOMPurify.sanitize(
                                customButton?.modal?.body || ""
                              ),
                            }}
                          />
                        ),
                      },
                },
              },
            },
          };
        }
      ),
      link: {
        value: !!cta?.cta_title
          ? {
              text: cta?.cta_title,
              anchor: "",
              href:
                replaceContentStackURL(cta?.cta_url) ||
                resolveAbsoluteUrl(cta?.cta_url, i18nContext) ||
                "",
              url:
                replaceContentStackURL(cta?.cta_url) ||
                resolveAbsoluteUrl(cta?.cta_url, i18nContext) ||
                "",
              linktype: cta?.cta_url ? "external" : "modelpopup",
              modelpopupcontent: cta?.cta_url
                ? null
                : {
                    title: cta?.modal?.title ?? "",
                    description: (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(cta?.modal?.body || ""),
                        }}
                      />
                    ),
                  },
            }
          : {},
      },
      buttonIcon: {
        fields: {
          value: {
            value: cta?.cta_icon ?? "",
          },
        },
      },
      linkGALabelName: {
        value: "",
      },
      gaLabelName: {
        value: cta?.ga_label || cta?.cta_title || "",
      },
      gaCategoryName: {
        value: "",
      },
    },
    useParentPadding,
  };

  return (
    <Wrapper
      itemsPerLine={items_per_line}
      backgroundColor={background_color}
      foregroundColor={foreground_color}
      useParentPadding={useParentPadding}
      designSettings={design_settings}
      haveMarginTop={csData.data.isHorizontalVertical && !title}
      className={clsx({
        "layout-center": design_settings === CARD_LIST_VARIANT.CENTER_ICON,
      })}
      style={
        design_settings === CARD_LIST_VARIANT.HK_SUPPORT_PAGE_2
          ? {
              backgroundColor: background_color,
            }
          : {}
      }
    >
      {design_settings === CARD_LIST_VARIANT.HORIZONTAL_CARD ? (
        <HorizontalCardList data={data} backgroundColor={background_color} />
      ) : design_settings === CARD_LIST_VARIANT.HK_SUPPORT_PAGE_2 ? (
        <ModernCardList data={csData.data} />
      ) : (
        <CardList cardListProps={data} />
      )}
    </Wrapper>
  );
};
