import { CSReminder } from "components/Reminder/reminder.type";
import { TableContent } from "components/Table/type";

export enum ModernCardListCtaPosition {
  RIGHT_OF_TITLE_BLOCK = "right_of_title_block",
  BOTTOM_OF_BLOCK = "below_of_block",
}

export enum ModernCardListCtaDesign {
  WHITE_BACKGROUND = "white_background",
  TRANSPARENT_BACKGROUND = "transparent_background",
}

export interface CSCardListProps {
  data: {
    additionalData?: {
      custom_ctas?: {
        icon: string;
        index: number;
        modal: {
          title: string;
          body: string;
        };
        open_in_new_tab: boolean;
        title: string;
        url: string;
      }[];
    };
    title: string;
    subtitle: {
      text: string;
      icon: string;
    };
    description: string;
    spacing: {
      padding_top: string;
      padding_bottom: string;
    };
    reminder: [CSReminder];
    usp_section: {
      usp_title: string;
      usp_description: string;
      usp_icon: string;
      cta: {
        title: string;
        url: string;
        open_in_new_tab: boolean;
        icon: string;
      };
      table_reference: [TableContent];
    }[];
    cta?: {
      cta_title?: string;
      ga_label?: string;
      cta_icon?: string;
      cta_url?: string;
      cta_description?: string;
      cta_position: ModernCardListCtaPosition;
      cta_design_settings: ModernCardListCtaDesign;
      open_in_new_tab: boolean;
      modal?: {
        title: string;
        body: string;
      };
    };
    items_per_line: number;
    items_to_show_desktop: number;
    items_to_show_mobile: number;
    background_color: string;
    foreground_color: string;
    see_more_text: string;
    design_settings: string;
    useParentPadding?: boolean;
    isHorizontalVertical: boolean;
  };
}
